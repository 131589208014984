import { ZodError } from "zod";

import { unwrap } from "../utils";
import { ConfigValueNotFoundError } from "../utils/error";

export const unwrapConfig = <T>(
  decoded: {
    success: boolean;
    data?: T;
    error?: ZodError;
  },
  key?: string,
) => {
  return unwrap(decoded, ConfigValueNotFoundError, key);
};
