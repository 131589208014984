import styled from "@emotion/styled";
import { Border, Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

const { Title } = Typography;

type Props = {
  className?: string;
  children: string;
};

const H5: FunctionComponent<Props> = ({ className, children }) => {
  return (
    <Root className={className} level={5}>
      {children}
    </Root>
  );
};

const Root = styled(Title)`
  box-sizing: border-box;
  border-bottom: solid 1px ${Border.Base};
  padding: 8px;
  width: 100%;
`;

export default H5;
