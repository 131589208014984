import { BaseError } from "make-error-cause";

export type ApplicationError =
  | ApiDataNotFoundError
  | ApiRequestFailedError
  | InternalServerError
  | UnexpectedFormatError
  | ValidationError;

// 環境変数が設定されていなかった場合
export class ConfigValueNotFoundError extends BaseError {}

// リクエストパラメータのバリデーションエラー
export class ValidationError extends BaseError {}

// APIへの認証が失敗した場合
export class ApiAuthorizationFailedError extends BaseError {}

// APIへのデータ問い合わせの結果が見つからなかった場合
export class ApiDataNotFoundError extends BaseError {}

// APIリクエストが失敗した場合
export class ApiRequestFailedError extends BaseError {}

// APIからの入力データが期待と異なるフォーマットの場合
export class UnexpectedFormatError extends BaseError {}

// その他致命的なエラー
export class InternalServerError extends BaseError {}

// Newtのエラーハンドリング
export class NewtApiForbiddenError extends BaseError {}

export class NewtApiRequestFailedError extends BaseError {}

export class NewtUnexpectedError extends BaseError {}
