import styled from "@emotion/styled";
import {
  Background,
  BookmarkIcon,
  Border,
  CareerIcon,
  LectureIcon,
  ProfileIcon,
  SettingsIcon,
  SignOutIcon,
  StarIcon,
  Typography,
} from "@ibeckinc/ui-elements";
import { signOut, useSession } from "next-auth/react";
import React, { FunctionComponent, useCallback } from "react";
import { toast } from "react-toastify";

import { pagesPath } from "../../lib/$path";
import { device } from "../../utils";
import { NextLink } from "../NextLink";

const { Title } = Typography;

type Props = {
  className?: string;
  unread?: boolean;
};

const UserMenu: FunctionComponent<Props> = ({ className }) => {
  const { status } = useSession();

  const handleSignOut = useCallback(async () => {
    if (status === "authenticated") {
      await signOut({ callbackUrl: "/" }).catch(() =>
        toast.error("サインアウトできませんでした"),
      );
    }
  }, [status]);

  return (
    <LinkList className={className}>
      <LinkItem>
        <ProfileIcon color={Background.Dark} size={25} />
        <_LinkAnchor href={pagesPath.account.profile.$url()}>
          <Title level={5}>プロフィール</Title>
        </_LinkAnchor>
      </LinkItem>
      <LinkItem>
        <CareerIcon color={Background.Dark} size={25} />
        <_LinkAnchor href={pagesPath.account.career_profile.$url()}>
          <Title level={5}>キャリアプロフィール</Title>
        </_LinkAnchor>
      </LinkItem>
      <Divider />
      <LinkItem>
        <LectureIcon color={Background.Dark} size={25} />
        <_LinkAnchor href={pagesPath.account.seminars.$url()}>
          <Title level={5}>セミナー</Title>
        </_LinkAnchor>
      </LinkItem>
      <LinkItem>
        <StarIcon color={Background.Dark} size={25} />
        <_LinkAnchor href={pagesPath.account.watchlist.$url()}>
          <Title level={5}>ウォッチリスト</Title>
        </_LinkAnchor>
      </LinkItem>
      <LinkItem>
        <BookmarkIcon color={Background.Dark} size={25} />
        <_LinkAnchor href={pagesPath.account.bookmark.$url()}>
          <Title level={5}>ブックマーク記事</Title>
        </_LinkAnchor>
      </LinkItem>
      <Divider />
      <LinkItem>
        <SettingsIcon color={Background.Dark} size={25} />
        <_LinkAnchor href={pagesPath.account.setting.$url()}>
          <Title level={5}>設定</Title>
        </_LinkAnchor>
      </LinkItem>
      <Divider />
      <LinkItem>
        <SignOutIcon color={Background.Dark} size={25} />
        <LinkText>
          <Title level={5} onClick={handleSignOut}>
            サインアウト
          </Title>
        </LinkText>
      </LinkItem>
    </LinkList>
  );
};

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  list-style: none;
  margin: -10px 0 -10px 0;
  padding: 20px;
  width: 100%;
`;

const LinkItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 48px;
  line-height: 48px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: -20px;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${Background.Light};
  }

  @media ${device.mobile} {
    justify-content: space-around;
    margin-left: -32px;
  }
`;

const _LinkAnchor = styled(NextLink)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 16px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 70%;
`;

const LinkText = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 16px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 70%;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${Border.Base};
  margin: 8px 0 8px -20px;
  width: calc(100% + 40px); // To ignore Popover Padding
  height: 1px;
`;

export default UserMenu;
