import { Session } from "next-auth";
import { z } from "zod";

import { getFetcher } from "../../hooks/useFetcher";
import { decode } from "../../utils";

export const GetUnreadNotificationsKey = "/students/v2/me/notifications/unread";

export async function getUnreadNotificationIds({
  session,
}: {
  session: Session | null;
}): Promise<string[]> {
  const fetcher = getFetcher<string[]>(
    { headers: { Authorization: "Bearer " + session?.accessToken } },
    ({ data }) => decode(z.array(z.string()).safeParse(data)),
  );
  return fetcher(GetUnreadNotificationsKey);
}
