import { AxiosResponse } from "axios";
import useSWR from "swr";

import { AxiosOptions, buildApi, useApi } from "../lib/apiClient";

export const useFetcher = <Data>(
  axiosOptions: AxiosOptions | undefined = undefined,
  decode: (data: AxiosResponse<Data>) => Data,
) => {
  let apiClient = useApi();
  if (axiosOptions) apiClient = buildApi(axiosOptions);
  return (url: string) => apiClient.get(url).then((r) => decode(r));
};

export const getDeleter = <Params, Data>(
  axiosOptions: AxiosOptions = {},
  params: Params,
  decode?: (data: AxiosResponse<Data>) => Data,
) => {
  const apiClient = buildApi(axiosOptions);
  return (url: string) =>
    apiClient.delete(url, { data: params }).then((r) => {
      if (decode) return decode(r);
      return r.data;
    });
};

export const getFetcher = <Data>(
  axiosOptions: AxiosOptions = {},
  decode: (data: AxiosResponse<Data>) => Data,
) => {
  const apiClient = buildApi(axiosOptions);
  return (url: string) => apiClient.get(url).then((r) => decode(r));
};

export const getPatcher = <Params, Data>(
  axiosOptions: AxiosOptions = {},
  params: Params,
  decode?: (data: AxiosResponse<Data>) => Data,
) => {
  const apiClient = buildApi(axiosOptions);
  return (url: string) =>
    apiClient.patch(url, params).then((r) => {
      if (decode) return decode(r);
      return r.data;
    });
};

export const getPoster = <Params, Data>(
  axiosOptions: AxiosOptions = {},
  params: Params,
  decode?: (data: AxiosResponse<Data>) => Data,
) => {
  const apiClient = buildApi(axiosOptions);
  return (url: string) =>
    apiClient.post(url, params).then((r) => {
      if (decode) decode(r);
      return;
    });
};

export default useSWR;
