import { createContext, useContext, useMemo } from "react";

export type UserAgent = NavigatorID["userAgent"];

export const UserAgentContext = createContext<UserAgent | null>(null);

export const getMobileDetect = (userAgent: UserAgent) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};

export const useMobile = () => {
  const userAgent = useContext(UserAgentContext);

  const _useMobile = useMemo(() => {
    const props = getMobileDetect(userAgent ?? "");
    return props;
  }, [userAgent]);

  return _useMobile;
};
