import styled from "@emotion/styled";
import { Background, Button } from "@ibeckinc/ui-elements";
import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";

interface StickMenuMessage {
  label: string;
  onClick: React.MouseEventHandler;
}

const stickMenuContextObj = {
  openStickMenu: (args: {
    label: string;
    onClick: React.MouseEventHandler;
  }): void => {
    throw new Error(`not implemented ${args.label} ${args.onClick}`);
  },
  closeStickMenu: (): void => {
    throw new Error("not implemented");
  },
};

const StickMenuContext = createContext(stickMenuContextObj);

export const useStickMenu = () => useContext(StickMenuContext);

export const StickMenuProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const [messageInfo, setMessageInfo] = useState<StickMenuMessage | undefined>(
    undefined,
  );

  const openStickMenu: (typeof stickMenuContextObj)["openStickMenu"] = (
    message,
  ) => {
    setMessageInfo(message);
  };

  const closeStickMenu = () => {
    setMessageInfo(undefined);
  };

  const value: typeof stickMenuContextObj = { openStickMenu, closeStickMenu };

  return (
    <StickMenuContext.Provider value={value}>
      {children}
      {messageInfo && (
        <MobileStickMenu>
          <_Button size="small" onClick={messageInfo.onClick}>
            {messageInfo.label}
          </_Button>
        </MobileStickMenu>
      )}
    </StickMenuContext.Provider>
  );
};

const MobileStickMenu = styled.div`
  background: ${Background.Base};
  box-shadow: 0px -4px 6px rgba(59, 63, 75, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100vw;
  z-index: 1800;
`;

const _Button = styled(Button)`
  width: 240px;
`;
