import {
  DefaultTextTheme,
  TBold,
  TextThemeContext,
  TLetterSpacing,
  TLineHeight,
  TSize,
  TTheme,
} from "@ibeckinc/ui-elements";
import React from "react";

import { useMobile } from "../hooks/useMobile";

export const MobileTextTheme: TTheme = {
  h1: {
    bold: "bold" as TBold,
    lineHeight: "big" as TLineHeight,
    size: "big" as TSize,
  },
  h2: {
    bold: "bold" as TBold,
    letterSpacing: "big" as TLetterSpacing,
    lineHeight: "medium" as TLineHeight,
    size: "grande" as TSize,
  },
  h3: {
    bold: "bold" as TBold,
    letterSpacing: "medium" as TLetterSpacing,
    lineHeight: "medium" as TLineHeight,
    size: "regular" as TSize,
  },
  h4: {
    bold: "bold" as TBold,
    letterSpacing: "medium" as TLetterSpacing,
    lineHeight: "medium" as TLineHeight,
    size: "medium" as TSize,
  },
  h5: {
    bold: "bold" as TBold,
    lineHeight: "medium" as TLineHeight,
    size: "medium" as TSize,
  },
  bodyLight: {
    bold: "light" as TBold,
    lineHeight: "medium" as TLineHeight,
    size: "small" as TSize,
  },
  body: {
    lineHeight: "medium" as TLineHeight,
    size: "small" as TSize,
  },
  bodyBold: {
    bold: "bold" as TBold,
    lineHeight: "medium" as TLineHeight,
    size: "small" as TSize,
  },
  button: {
    bold: "bold" as TBold,
    lineHeight: "small" as TLineHeight,
    size: "small" as TSize,
  },
  caption: {
    letterSpacing: "small" as TLetterSpacing,
    lineHeight: "small" as TLineHeight,
    size: "mini" as TSize,
  },
  captionBold: {
    bold: "bold" as TBold,
    letterSpacing: "small" as TLetterSpacing,
    lineHeight: "small" as TLineHeight,
    size: "mini" as TSize,
  },
  chip: {
    bold: "bold" as TBold,
    lineHeight: "small" as TLineHeight,
    size: "tiny" as TSize,
  },
  fontFamily: "Noto Sans JP",
};

type Props = {
  children: React.ReactNode;
};

export const TextThemeContextProvider: React.FC<Props> = ({ children }) => {
  const { isMobile } = useMobile();

  return (
    <TextThemeContext.Provider
      value={isMobile() ? MobileTextTheme : DefaultTextTheme}
    >
      {children}
    </TextThemeContext.Provider>
  );
};
