import styled from "@emotion/styled";
import { Border, Color, Typography } from "@ibeckinc/ui-elements";
import React, { ChangeEvent, useCallback } from "react";

import { device } from "../../utils";

const { Text } = Typography;

type Props<T> = {
  className?: string;
  options: { id: T; name: string }[];
  title?: string;
  value: T;
  setValue: (value: T) => void;
};

export function Select<T>({
  className,
  options,
  title,
  value,
  setValue,
}: Props<T>): JSX.Element {
  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setValue(e.target.value as T);
    },
    [setValue],
  );

  return (
    <Root className={className}>
      {title && (
        <Label bold="bold" multiline>
          {title}
        </Label>
      )}
      <InnerSelect onChange={handleChangeValue} value={value as string}>
        <option value="">選択肢</option>
        {options.map((option, idx) => (
          <option key={`menu-item-${idx}`} value={option.id as string}>
            {option.name}
          </option>
        ))}
      </InnerSelect>
    </Root>
  );
}

const Root = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled(Text)`
  flex: 1;
  margin-bottom: 10px;
`;

const InnerSelect = styled.select`
  height: 40px;
  min-width: 80px;
  border: 1px solid ${Border.Base};
  border-radius: 8px;
  padding: 0 15px;
  background: ${Color.Background.Base};

  &:focus {
    outline: 1px solid ${Border.Brand};
    border: 0px solid ${Border.Base};
  }

  @media ${device.mobile} {
    min-width: 30px;
  }
`;
