import styled from "@emotion/styled";
import { Background, Border } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

const PopoverSizes = {
  small: 200,
  medium: 230,
  large: 280,
};
type PopoverSize = keyof typeof PopoverSizes;

export type Props = {
  className?: string;
  children: React.ReactNode;
  size?: PopoverSize;
  speechBubble?: boolean;
  onClickOverlay: () => void;
};

const Popover: FunctionComponent<Props> = ({
  className,
  children,
  size = "medium",
  speechBubble = false,
  onClickOverlay,
}) => {
  return (
    <>
      <Root className={className}>
        <PopoverContainer size={size} speechBubble={speechBubble}>
          {children}
        </PopoverContainer>
      </Root>
      <Overlay onClick={onClickOverlay} />
    </>
  );
};

const Root = styled.div``;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const PopoverContainer = styled.div<{
  size: PopoverSize;
  speechBubble: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  width: ${({ size }) => PopoverSizes[size]}px;
  background: ${Background.Base};
  box-shadow: 0 10px 30px rgb(0 0 0 / 50%);
  padding: 0;
  text-align: center;
  border: 1px solid ${Border.Base};
  color: #666666;
  font-size: 20px;
  font-weight: bold;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;

  &:after,
  &:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: 87%;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-width: 24px;
    border-bottom-width: 24px;
    border-left-width: 11px;
    border-right-width: 11px;
    margin-left: -11px;
    border-bottom-color: ${Border.Transparent};
  }

  ${({ speechBubble }) =>
    !speechBubble &&
    `
    &:before {
    border-color: rgba(0, 163, 223, 0);
    border-top-width: 26px;
    border-bottom-width: 26px;
    border-left-width: 12px;
    border-right-width: 12px;
    margin-left: -12px;
    margin-bottom: 0px;
    border-bottom-color: ${Background.Base};
  }
  `}
`;

export default Popover;
