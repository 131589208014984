import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React, { FunctionComponent, useMemo } from "react";

import { useMobile } from "../../hooks/useMobile";
import { pagesPath } from "../../lib/$path";
import { LayerPrimus } from "../../lib/constants/LayerDepth";
import { device } from "../../utils";
import { Footer } from "../Footer";
import { Head } from "../Head";
import { Header } from "../Header";

type Props = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
};

const PagesWithoutHeaderMenuAndFooter = [
  pagesPath.sign_up.$url().pathname as string,
  pagesPath.reset_password.$url().pathname as string,
  pagesPath.reset_password_requested.$url().pathname as string,
  pagesPath.jobseekers.welcome.$url().pathname as string,
] as const;

export const MainLayout: FunctionComponent<Props> = ({
  className,
  children,
  title,
}) => {
  const { isMobile } = useMobile();
  const router = useRouter();

  const withoutHeaderMenuAndFooter = useMemo(
    () => PagesWithoutHeaderMenuAndFooter.includes(router.pathname),
    [router.pathname],
  );

  return (
    <Root className={className}>
      <Head title={title} />
      <_Header disabled={withoutHeaderMenuAndFooter} />
      <Content>{children}</Content>
      {!withoutHeaderMenuAndFooter && !isMobile() && <_Footer />}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  margin: 0 auto 120px;
  width: 100%;

  @media ${device.mobile} {
    margin: 0 auto 84px;
  }
`;

const _Header = styled(Header)`
  position: sticky;
  top: 0;
  z-index: ${LayerPrimus};
`;

const _Footer = styled(Footer)`
  margin-top: auto;
`;
