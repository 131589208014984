import { Session } from "next-auth";
import useSWR from "swr";

import { getFetcher, useFetcher } from "../../hooks/useFetcher";
import { getPatcher } from "../../hooks/useFetcher";
import { Notifications, NotificationsResponse } from "../../types/notification";
import { decode, url } from "../../utils";

export const GetNotificationsKey = (args: { limit?: number; page?: number }) =>
  url("/students/v2/me/notifications", { ...args });

export const useGetNotifications = ({
  session,
  limit,
  page,
}: {
  session: Session | null;
  limit?: number;
  page?: number;
}) => {
  return useSWR<Notifications>(
    GetNotificationsKey({ limit, page }),
    useFetcher(
      { headers: { Authorization: "Bearer " + session?.accessToken } },
      ({ data }) => decode(NotificationsResponse.safeParse(data)),
    ),
    { errorRetryInterval: 100, errorRetryCount: 2 },
  );
};

export async function getNotifications({
  session,
  limit,
  page,
}: {
  session: Session | null;
  limit?: number;
  page?: number;
}): Promise<Notifications> {
  const fetcher = getFetcher<Notifications>(
    { headers: { Authorization: "Bearer " + session?.accessToken } },
    ({ data }) => decode(NotificationsResponse.safeParse(data)),
  );
  return fetcher(GetNotificationsKey({ limit, page }));
}

export async function readNotifications({
  ids,
  session,
}: {
  ids: string[];
  session: Session | null;
}): Promise<void> {
  const patcher = getPatcher<{ notificationIds: string[] }, void>(
    { headers: { Authorization: "Bearer " + session?.accessToken } },
    { notificationIds: ids },
    undefined,
  );
  return patcher(GetNotificationsKey({}));
}
