import { Session } from "next-auth";
import useSWR from "swr";
import { z } from "zod";

import { getFetcher, getPoster, useFetcher } from "../../hooks/useFetcher";
import { Survey, SurveyResponse } from "../../types/survey";
import { decode } from "../../utils";

export const GetSurveysKey = (id?: string) =>
  `/surveys/v1${id ? `/${id}` : ""}`;

export const useGetSurveys = ({ session }: { session: Session | null }) => {
  return useSWR<Survey[]>(
    GetSurveysKey(),
    useFetcher(
      { headers: { Authorization: "Bearer " + session?.accessToken } },
      ({ data }) => decode(z.array(SurveyResponse).safeParse(data)),
    ),
    { errorRetryInterval: 100, errorRetryCount: 2 },
  );
};

export async function getSurveys({
  session,
}: {
  session: Session | null;
  limit?: number;
  page?: number;
}): Promise<Survey[]> {
  const fetcher = getFetcher<Survey[]>(
    { headers: { Authorization: "Bearer " + session?.accessToken } },
    ({ data }) => decode(z.array(SurveyResponse).safeParse(data)),
  );
  return fetcher(GetSurveysKey());
}

export async function respondSurvey({
  id,
  rowOptions,
  session,
}: {
  id: string;
  rowOptions: { optionId: string; rowId: string }[];
  session: Session | null;
}): Promise<void> {
  const poster = getPoster<
    { rowOptions: { optionId: string; rowId: string }[] },
    void
  >(
    { headers: { Authorization: "Bearer " + session?.accessToken } },
    { rowOptions },
    undefined,
  );
  return poster(GetSurveysKey(id));
}
