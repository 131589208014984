import { z } from "zod";

import { unwrapConfig } from "./wrapper";

// APP
export const APP_ENV = unwrapConfig(
  z.enum(["local", "stg", "prd"]).safeParse(process.env.APP_ENV),
  "APP_ENV",
);

export const APP_HOST = unwrapConfig(
  z.string().min(1).safeParse(process.env.APP_HOST),
  "APP_HOST",
);

export const NEXTAUTH_URL = unwrapConfig(
  z.string().min(1).safeParse(process.env.NEXTAUTH_URL),
  "NEXTAUTH_URL",
);

export const NEWT_CONTACT_FORM_ENDPOINT = unwrapConfig(
  z.string().min(1).safeParse(process.env.NEWT_CONTACT_FORM_ENDPOINT),
  "NEWT_CONTACT_FORM_ENDPOINT",
);

export const RECAPTCHA_SITE_KEY = unwrapConfig(
  z.string().min(1).safeParse(process.env.RECAPTCHA_SITE_KEY),
  "RECAPTCHA_SITE_KEY",
);
