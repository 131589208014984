import styled from "@emotion/styled";
import Link, { LinkProps } from "next/link";
import React from "react";
/*
NOTE:
- Next.js13よりnext/linkの子要素が<a>でなくなり、<a>が使用されている場合は削除の必要がある
- 参考: https://nextjs.org/blog/next-13#breaking-changes
*/
type Props = {
  className?: string;
  children: React.ReactNode;
} & LinkProps &
  React.RefAttributes<HTMLAnchorElement>;

export const NextLink: React.FunctionComponent<Props> = ({
  children,
  ...props
}) => <Root {...props}>{children}</Root>;

const Root = styled(Link)`
  text-decoration: none;
`;
