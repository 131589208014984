import styled from "@emotion/styled";
import { Background, Button, Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import Logo from "../../components/Logo";
import { pagesPath } from "../../lib/$path";
import FOOTER_LINKS from "./FooterLinks";

const { Link, Text } = Typography;

export type Props = {
  className?: string;
};

const thisYear = new Date().getFullYear();

export const Footer: FunctionComponent<Props> = ({ className }) => (
  <Root className={className}>
    <LogoContainer>
      <Logo color="white" />
      <LogoText>
        <Text
          alignment="start"
          bold="light"
          color="white"
          variant="button"
          multiline
        >
          ビジネスプロフェッショナル・リーダーへ
        </Text>
        <Text
          alignment="start"
          bold="light"
          color="white"
          variant="button"
          multiline
        >
          キャリア最大化を支援するAlternative Careers
        </Text>
      </LogoText>
    </LogoContainer>
    <FooterContent>
      <MenuContainer>
        {FOOTER_LINKS.map(({ category, links }, i) => (
          <MenuLinks key={`footer-menu-links-${i}`}>
            <MenuLinkTitle color="white">{category}</MenuLinkTitle>
            {links.map((link, j) => {
              return (
                <Link
                  key={`footer-link-${i}-${j}`}
                  href={link.url}
                  target="_blank"
                  color="light"
                  size="small"
                >
                  {link.title}
                </Link>
              );
            })}
          </MenuLinks>
        ))}
      </MenuContainer>
      <ButtonContainer>
        <_Button href={pagesPath.contact.$url().pathname} target="_blank">
          お問い合わせ
        </_Button>
        <_Button
          color="secondary"
          href="https://www.ibeck.co.jp/contact"
          target="_blank"
        >
          企業様お問い合わせ
        </_Button>
      </ButtonContainer>
    </FooterContent>
    <CopyRight>
      <Text alignment="center" color="white" size="small">
        {`© 2016-${thisYear} alternative careers`}
      </Text>
    </CopyRight>
  </Root>
);

const Root = styled.footer`
  background: ${Background.BlueBlack};
  box-sizing: border-box;
  height: 527px;
  min-width: 1000px;
  padding: 50px;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
  padding: 0;
`;

const LogoText = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 80px 0px;
  justify-content: center;
`;

const MenuContainer = styled.div`
  display: flex;
  gap: 80px;
  margin-right: 80px;
`;

const MenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`;

const MenuLinkTitle = styled(Text)`
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const _Button = styled(Button)`
  width: 200px;
`;

const CopyRight = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
