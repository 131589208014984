import useSWR, { getFetcher, useFetcher } from "../../hooks/useFetcher";
import { PageMetadata, PageMetadataResponse } from "../../types/metadata";
import { decode } from "../../utils";

export const GetPageMetadataKey = (path: string) =>
  `/metadata/v2/pages?path=${path}`;

export const useGetPageMetadata = ({ path }: { path: string }) => {
  return useSWR<PageMetadata>(
    GetPageMetadataKey(path),
    useFetcher(undefined, ({ data }) =>
      decode(PageMetadataResponse.safeParse(data)),
    ),
    { errorRetryInterval: 100, errorRetryCount: 2 },
  );
};

export async function getPageMetadata({
  path,
}: {
  path: string;
}): Promise<PageMetadata> {
  const fetcher = getFetcher<PageMetadata>(undefined, ({ data }) =>
    decode(PageMetadataResponse.safeParse(data)),
  );
  return fetcher(GetPageMetadataKey(path));
}
