import styled from "@emotion/styled";
import { Background, Button, Typography } from "@ibeckinc/ui-elements";
import { List } from "immutable";
import React, {
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Survey } from "../../types/survey";
import { device } from "../../utils";
import { Select } from "../Select";

const { Paragraphs, Text, Title } = Typography;

type RowOption = { optionId: string; rowId: string };

export type SurveyFormValue = {
  id: string;
  rowOptions: RowOption[];
};

type Props = {
  className?: string;
  survey: Survey;
  onSubmit: (e: SyntheticEvent, value: SurveyFormValue) => void;
};

export const SurveyForm: FunctionComponent<Props> = ({
  className,
  survey,
  onSubmit,
}) => {
  const [rowOptions, setRowOptions] = useState<List<RowOption>>(
    List(survey.rows.map((row) => ({ optionId: "", rowId: row.id }))),
  );

  const handleSelectValue = useCallback((rowId: string, value: string) => {
    setRowOptions((prev) =>
      prev.map((ro) => (ro.rowId === rowId ? { ...ro, optionId: value } : ro)),
    );
  }, []);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onSubmit(e, { id: survey.id, rowOptions: rowOptions.toArray() });
    },
    [onSubmit, rowOptions, survey],
  );

  const disabled = useMemo(
    () => rowOptions.some((ro) => ro.optionId.length === 0),
    [rowOptions],
  );

  return (
    <Root className={className}>
      <_Title level={2} variant={2}>
        {survey.name}
      </_Title>
      <ParagraphsContainer>
        {survey.description.split(/(\n)/).map((item, index) => (
          <React.Fragment key={index}>
            {item.match(/\n/) ? null : (
              <_Paragraphs multiline>{item}</_Paragraphs>
            )}
          </React.Fragment>
        ))}
      </ParagraphsContainer>
      {survey.rows.map((row) => (
        <Row key={row.id}>
          <TextWrapper>
            <Text variant="caption" bold="bold">
              {`${row.companyName}${row.department ? ` (${row.department})` : ""}`}
            </Text>
          </TextWrapper>
          <SelectWrapper>
            <Select<string>
              options={survey.options.map((opt) => ({
                id: opt.id,
                name: opt.value,
              }))}
              value={
                rowOptions.find((ro: RowOption) => ro.rowId === row.id)
                  ?.optionId || ""
              }
              setValue={(value) => handleSelectValue(row.id, value)}
            />
          </SelectWrapper>
        </Row>
      ))}
      <ButtonContainer>
        <Button disabled={disabled} size="small" onClick={handleSubmit}>
          登録
        </Button>
      </ButtonContainer>
    </Root>
  );
};

const Root = styled.form`
  box-sizing: border-box;
  padding: 0 24px;
  width: 100%;
`;

const _Title = styled(Title)`
  font-size: 16px;
  height: 44px;
  margin-bottom: 36px;
  position: relative;
  text-align: center;

  &::after {
    content: "";
    display: inline-block;
    background: ${Background.Brand};
    height: 2px;
    width: 72px;
    position: absolute;
    left: calc(50% - 36px);
    bottom: 0px;
  }
`;

const ParagraphsContainer = styled.div`
  margin-bottom: 24px;
`;

const _Paragraphs = styled(Paragraphs)`
  display: block;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
  width: 100%;

  @media ${device.mobile} {
    flex-wrap: wrap;
    margin-top: 16px;
  }
`;

const SelectWrapper = styled.div`
  width: 200px;
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;
