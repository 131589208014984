import { z } from "zod";

import { unwrapConfig } from "./wrapper";

// APP
export const APP_ENV = unwrapConfig(
  z.enum(["local", "stg", "prd"]).safeParse(process.env.APP_ENV),
  "APP_ENV",
);

export const APP_HOST = unwrapConfig(
  z.string().min(1).safeParse(process.env.APP_HOST),
  "APP_HOST",
);

export const API_HOST = unwrapConfig(
  z.string().min(1).safeParse(process.env.API_HOST),
  "API_HOST",
);

export const NEXTAUTH_SECRET = unwrapConfig(
  z.string().min(1).safeParse(process.env.NEXTAUTH_SECRET),
  "NEXTAUTH_SECRET",
);

export const NEXTAUTH_URL = unwrapConfig(
  z.string().min(1).safeParse(process.env.NEXTAUTH_URL),
  "NEXTAUTH_URL",
);

export const GTM_ID = unwrapConfig(
  z.string().optional().safeParse(process.env.GTM_ID),
  "GTM_ID",
);
