import { pagesPath } from "../../lib/$path";

const HEADER_LINKS = [
  {
    link: "初めての方へ",
    href: pagesPath.about.$url(),
  },
  {
    link: "セミナー",
    href: pagesPath.seminars.$url(),
  },
  {
    link: "共催企業",
    href: pagesPath.companies.sponsored.$url(),
  },
  {
    link: "企業情報",
    href: pagesPath.companies.$url(),
  },
  {
    link: "コンテンツ",
    href: pagesPath.articles.$url(),
  },
] as const;

export default HEADER_LINKS;
