import { z } from "zod";

export const NotificationResponse = z.object({
  id: z.string(),
  icon: z.string().nullable(),
  link: z.string().nullable(),
  message: z.string(),
  sentAt: z.string(),
  read: z.boolean(),
});
export type Notification = z.infer<typeof NotificationResponse>;

export const NotificationsResponse = z.object({
  data: z.array(NotificationResponse),
  total: z.number(),
});
export type Notifications = z.infer<typeof NotificationsResponse>;
