import { z } from "zod";

export const SurveyRowResponse = z.object({
  id: z.string(),
  companyId: z.string(),
  companyName: z.string(),
  department: z.string().nullable(),
});
export type SurveyRow = z.infer<typeof SurveyRowResponse>;

export const SurveyOptionResponse = z.object({
  id: z.string(),
  value: z.string(),
});
export type SurveyOption = z.infer<typeof SurveyOptionResponse>;

export const SurveyResponse = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  rows: z.array(SurveyRowResponse),
  options: z.array(SurveyOptionResponse),
});
export type Survey = z.infer<typeof SurveyResponse>;
