import H1 from "./H1";
import H2 from "./H2";
import H3 from "./H3";
import H5 from "./H5";

const Heading = {
  H1,
  H2,
  H3,
  H5,
};

export default Heading;
