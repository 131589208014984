export const isStringArray = (value: unknown) => {
  return Array.isArray(value) && value.every((val) => typeof val === "string");
};

export function url(path: string, params: { [key: string]: any }): string {
  const searchParams = new URLSearchParams({});

  Object.keys(params).forEach((key) => {
    if (typeof params[key] === "undefined") {
      return;
    } else if (isStringArray(params[key])) {
      params[key].forEach((value: string) => {
        searchParams.append(`${key}[]`, value);
      });
    } else {
      searchParams.set(key, params[key]);
    }
  });

  const queries = searchParams.toString();

  return queries === "" ? path : `${path}?${queries}`;
}
