import styled from "@emotion/styled";
import {
  Background,
  Border,
  CircularProgress,
  Pagination,
  Typography,
} from "@ibeckinc/ui-elements";
import { useSession } from "next-auth/react";
import React, { FunctionComponent, useCallback, useEffect } from "react";

import { NextLink } from "../NextLink";
import { NoResult } from "../NoResult";
import { readNotifications, useGetNotifications } from "./query";

const { Text } = Typography;

type Props = {
  className?: string;
  limit?: number;
  page?: number;
  paginate?: boolean;
  onPageChange?: (page: number) => void;
};

export const NotificationList: FunctionComponent<Props> = ({
  className,
  limit = 5,
  page = 1,
  paginate = false,
  onPageChange,
}) => {
  const { data: session } = useSession();

  const { data } = useGetNotifications({
    session,
    limit,
    page,
  });

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => {
      onPageChange && onPageChange(page);
    },
    [onPageChange],
  );

  useEffect(() => {
    return () => {
      const unreadIds = data?.data.filter((n) => !n.read).map((n) => n.id);
      unreadIds && session && readNotifications({ ids: unreadIds, session });
    };
  }, [data, session]);

  if (data?.total === 0) {
    return (
      <NoResultContainer>
        <NoResult>通知がありません</NoResult>
      </NoResultContainer>
    );
  } else if (data?.data.length === 0) {
    return (
      <NoResultContainer>
        <NoResult>通知がありません</NoResult>
      </NoResultContainer>
    );
  }

  return (
    <Root className={className}>
      <React.Suspense fallback={<CircularProgress />}>
        {data && (
          <>
            <NotificationContainer>
              {data.data.map(({ id, link, message, read }) => {
                const Item = NotificationItem.withComponent(link ? "a" : "div");
                const item = (
                  <Item key={`item-${id}`} link={!!link}>
                    <Box>
                      {message.includes("\n") ? (
                        message.split("\n").map((m, idx) => (
                          <Message
                            key={`message-${idx}`}
                            alignment="start"
                            variant="caption"
                            multiline
                          >
                            {m}
                          </Message>
                        ))
                      ) : (
                        <Message alignment="start" variant="caption" multiline>
                          {message}
                        </Message>
                      )}
                    </Box>
                    {!read && <Dot />}
                  </Item>
                );
                return link ? (
                  <NextLink key={`link-${id}`} href={link}>
                    {item}
                  </NextLink>
                ) : (
                  item
                );
              })}
              {/* FIXME: switch url after page implemented */}
              <_LinkAnchor href="/account/profile">
                <SeeAll color="blue" variant="caption">
                  すべて見る
                </SeeAll>
              </_LinkAnchor>
            </NotificationContainer>
            {paginate && onPageChange && (
              <_Pagination
                page={page}
                total={Math.ceil(data.total / 10)}
                onPageChange={handleChangePage}
              />
            )}
          </>
        )}
      </React.Suspense>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const NoResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  min-height: 150px;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: "center";
  padding: 8px 0 0;
  width: 100%;
`;

const NotificationItem = styled.div<{ link: boolean }>`
  cursor: ${({ link }) => (link ? "pointer" : "default")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 24px;
  min-height: 40px;
  width: 100%;

  &:hover {
    background: ${({ link }) =>
      link ? Background.Light : Background.Transparent};
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Message = styled(Text)`
  line-height: 16px;
  width: 100%;
`;

const Dot = styled.div`
  background: ${Background.Brand};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;

const _LinkAnchor = styled(NextLink)`
  cursor: pointer;
  border-top: 1px solid ${Border.Base};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  height: 40px;
  width: 100%;
  text-decoration: none;

  &:hover {
    background: ${Background.Light};
  }
`;

const SeeAll = styled(Text)`
  cursor: pointer;
  line-height: 40px;
`;

const _Pagination = styled(Pagination)`
  margin-top: 48px;
`;

export default NotificationList;
