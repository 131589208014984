import styled from "@emotion/styled";
import { Background, Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { device } from "../../utils";

const { Title } = Typography;

type Props = {
  className?: string;
  children: string;
};

const H2: FunctionComponent<Props> = ({ className, children }) => (
  <Root className={className} level={2}>
    {children}
  </Root>
);

const Root = styled(Title)`
  margin: 0 0 16px;

  &::before {
    content: "";
    display: inline-block;
    background: ${Background.Brand};
    height: 32px;
    width: 5px;
    margin: 0px 22px -6px 0;

    @media ${device.mobile} {
      margin: 0px 16px -4px 0;
      height: 24px;
    }
  }
`;

export default H2;
