import styled from "@emotion/styled";
import {
  AccountCircleIcon as UserIcon,
  Background,
  Border,
  Button,
  Typography,
} from "@ibeckinc/ui-elements";
import { useSession } from "next-auth/react";
import React, { FunctionComponent, useState } from "react";

import { pagesPath } from "../../lib/$path";
import { LayerUno } from "../../lib/constants/LayerDepth";
import { NextLink } from "../NextLink";
import HEADERLinkAnchorS from "./HeaderLinks";
import UserMenu from "./UserMenu";

const { Title } = Typography;

interface Props {
  className?: string;
}

const SPMenuModal: FunctionComponent<Props> = ({ className }) => {
  const { status } = useSession();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Root className={className}>
      {status === "unauthenticated" ? (
        <ButtonContainer>
          <NextLink href={pagesPath.sign_in.$url()}>
            <Button color="secondary" size="small" variant="outline">
              ログイン
            </Button>
          </NextLink>
          <NextLink href={pagesPath.sign_up.$url()}>
            <Button size="small">会員登録</Button>
          </NextLink>
        </ButtonContainer>
      ) : (
        <UserIcon size={32} onClick={() => setMenuOpen(true)} />
      )}
      {!menuOpen && (
        <LinksArea>
          {HEADERLinkAnchorS.map(({ link, href }, idx) => (
            <NextLink key={idx} href={href}>
              <LinkTextWrapper>
                <Title level={5}>{link}</Title>
              </LinkTextWrapper>
            </NextLink>
          ))}
        </LinksArea>
      )}
      {menuOpen && (
        <_UserMenu>
          <UserMenu />
        </_UserMenu>
      )}
    </Root>
  );
};

const Root = styled.div`
  background: ${Background.Base};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: ${LayerUno};
`;

const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  gap: 20px;
  padding-bottom: 20px;
`;

const LinksArea = styled.div`
  border-top: solid 1px ${Border.Base};
  border-bottom: solid 1px ${Border.Base};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 0;
`;

const LinkTextWrapper = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;

const _UserMenu = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export default SPMenuModal;
