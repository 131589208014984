import { AxiosResponse } from "axios";

import { ApiDataNotFoundError, ApiRequestFailedError } from "./error";
import { logger } from "./logger";

const SUCCESS_STATUS = [200, 201, 204, 304];

class ApiResponseDecodeError extends ApiRequestFailedError {}
export const handleApiResponse = async <Res extends AxiosResponse>(
  res: Res,
): Promise<Res> => {
  if (!SUCCESS_STATUS.includes(res.status)) {
    let error: ApiRequestFailedError;
    try {
      if (res.status === 404) {
        error = new ApiDataNotFoundError(
          `status:${res.status}  url:${res.config.url}  body_text=${res.data.error.messages}`,
        );
      } else {
        error = new ApiRequestFailedError(
          `status:${res.status}  url:${res.config.url}  body_text=${res.data.error.messages}`,
        );
      }
    } catch (e) {
      error = new ApiResponseDecodeError(
        `status:${res.status}  url:${res.config.url}`,
      );
    }
    logger.error(error, error.message);
    throw error;
  }
  return res;
};
