import { BaseError } from "make-error-cause";
import { ZodError } from "zod";

import { UnexpectedFormatError } from "./error";

export const unwrap = <T>(
  decoded: { success: boolean; data?: T; error?: ZodError },
  ErrorConstructor?: typeof BaseError,
  key?: string,
): T => {
  if (!decoded.success) {
    const e = new UnexpectedFormatError(decoded?.error?.message);
    throw ErrorConstructor
      ? new ErrorConstructor(`${key ? key + " " : ""}Error on decodeZod`, e)
      : e;
  }
  return decoded.data!;
};
