import styled from "@emotion/styled";
import { Background, Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

const { Title } = Typography;

type Props = {
  className?: string;
  children: string;
};

const H3: FunctionComponent<Props> = ({ className, children }) => (
  <Root className={className} level={3}>
    {children}
  </Root>
);

const Root = styled(Title)`
  padding-left: 24px;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    background: ${Background.Brand};
    height: 4px;
    width: 16px;
    position: absolute;
    top: 12px;
    left: 0;
  }
`;

export default H3;
