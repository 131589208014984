const FOOTER_LINKS = [
  {
    category: "Alternative Careersについて",
    links: [
      { title: "初めての方へ", url: "/about" },
      {
        title: "利用規約",
        url: "/terms",
      },
      {
        title: "プライバシーポリシー",
        url: "/privacy_policy",
      },
      { title: "運営会社", url: "https://www.ibeck.co.jp/" },
    ],
  },
  {
    category: "コンテンツ",
    links: [
      { title: "セミナー", url: "/seminars" },
      { title: "共催企業", url: "/companies/sponsored" },
      { title: "企業情報", url: "/companies" },
      { title: "記事一覧", url: "/articles" },
    ],
  },
];

export default FOOTER_LINKS;
