import styled from "@emotion/styled";
import { Background } from "@ibeckinc/ui-elements";
import React from "react";

type Props = {
  className?: string;
  children: React.ReactNode;
  show?: boolean;
};

export const Badge: React.FunctionComponent<Props> = ({
  className,
  children,
  show = false,
}) => {
  return (
    <Root className={className}>
      {children}
      {show && (
        <_Badge>
          <Dot />
        </_Badge>
      )}
    </Root>
  );
};

const Root = styled.span`
  position: relative;
`;

const _Badge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: ${Background.Base};
  top: 0px;
  right: 2px;
`;

const Dot = styled.div`
  background: ${Background.Brand};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;
