export const LoadingOverlay = 10000;

// Modal
export const LayerModal = {
  level1: {
    overlay: 5000,
    content: 5001,
  },
  level2: {
    overlay: 5010,
    content: 5011,
  },
  level3: {
    overlay: 5020,
    content: 5021,
  },
} as const;

export type ModalLayerLevel = (typeof LayerModal)[keyof typeof LayerModal];

// Header
export const LayerPrimus = 1000;

// SPHeader, HeaderMenu
export const LayerTres = 100;

// Modal, SPSeminarProgramFooter
export const LayerDos = 10;

// SPModal
export const LayerUno = 5;

export const LayerZero = 0;

export const LayerMinus = -1;

export const LayerMinusDos = -2;

export const LayerMinusQuat = -4;
