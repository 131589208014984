import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  subMinutes,
} from "date-fns";
import format from "date-fns/format";
import ja from "date-fns/locale/ja";

import { DATE_FORMAT, WEEKDAYS } from "../lib/constants/Date";
import { range } from "./range";

export const formatDate = (
  date: string,
  setFormat = DATE_FORMAT.DEFAULT_DATETIME_FORMAT,
): string => {
  if (new Date(date).toString() === "Invalid Date") {
    return "Invalid Date";
  }
  return format(new Date(date), setFormat, { locale: ja });
};

export const convertTimestampToDate = (
  timestamp: number,
  setFormat = DATE_FORMAT.DEFAULT_DATETIME_FORMAT,
): string => {
  const date = new Date(timestamp * 1000);
  return format(date, setFormat, { locale: ja });
};

export const convertTimeSlotString = (
  from: string,
  to: string,
  setFromFormat = DATE_FORMAT.JP_DATETIME_SLASH_FORMAT,
  setToFormat = DATE_FORMAT.DATETIME_FORMAT,
): string => {
  if (new Date(from).toString() === "Invalid Date") {
    return "Invalid From Date";
  }

  if (new Date(to).toString() === "Invalid Date") {
    return "Invalid To Date";
  }

  return `${formatDate(from, setFromFormat)}-${formatDate(to, setToFormat)}`;
};

export const getYearOptions = () => {
  const date = new Date();
  return [...Array(50)].map((_, i) => (date.getFullYear() - 50 + i).toString());
};

export const getMonthOptions = () => range(1, 12).map((i) => i.toString());

export const getLastDate = (year: number, month: number): number => {
  const date = new Date(year, month + 1, 0);
  return date.getDate();
};

export const getDateOptions = (year: number, month: number): string[] => {
  return [...Array(getLastDate(year, month))].map((_, i) => (i + 1).toString());
};

export const formatTimeSlot = (startAt: Date, endAt: Date): string => {
  return `${startAt.getMonth() + 1}/${startAt.getDate()} (${
    WEEKDAYS[startAt.getDay()]
  })  ${("0" + startAt.getHours()).slice(-2)}:${(
    "0" + startAt.getMinutes()
  ).slice(-2)}-${("0" + endAt.getHours()).slice(-2)}:${(
    "0" + endAt.getMinutes()
  ).slice(-2)}`;
};

export const getNowISO = () => new Date().toISOString();

export const getTomorrowStartISO = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow.toISOString();
};

export const formatW3Date = (date: Date): string => {
  const dateTimeStr = format(date, DATE_FORMAT.W3_FORMAT, { locale: ja });
  return dateTimeStr + "+0" + Math.abs(date.getTimezoneOffset() / 60) + ":00";
};

// https://github.com/iBECKInc/alternative-issues/issues/979 対応後削除
export const newDateJST = () => {
  const today = new Date();
  return subMinutes(today, today.getTimezoneOffset());
};

const minuteSec = 60;
const hourSec = 60 * minuteSec;

export const remainingTimeMessage = (
  from: Date,
  to: Date,
  maxDiffDays?: number,
) => {
  const diff = differenceInSeconds(to, from);
  if (diff <= 0) return "";

  const diffMonths = to.getMonth() - from.getMonth();
  let diffDays = to.getDate() - from.getDate();
  if (diffMonths !== 0) {
    const endOfMonth = new Date(from.getFullYear(), from.getMonth(), 0);
    diffDays = endOfMonth.getDate() - from.getDate() + to.getDate();
  }

  if (diffDays > 0) {
    if (!maxDiffDays) {
      return `あと${diffDays}日`;
    }
    return diffDays > maxDiffDays ? "" : `あと${diffDays}日`;
  }

  if (diff >= hourSec) return `あと${differenceInHours(to, from)}時間`;
  if (diff >= minuteSec) return `あと${differenceInMinutes(to, from)}分`;
  return "あと1分";
};
