import styled from "@emotion/styled";
import { Background, Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

const { Title } = Typography;

type Props = {
  className?: string;
  children: string;
};

const H1: FunctionComponent<Props> = ({ className, children }) => (
  <Root className={className} alignment="center" level={1} variant={2}>
    {children}
  </Root>
);

const Root = styled(Title)`
  font-size: 16px;
  height: 44px;
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    background: ${Background.Brand};
    height: 2px;
    width: 72px;
    position: absolute;
    left: calc(50% - 36px);
    bottom: 0px;
  }
`;

export default H1;
