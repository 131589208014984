import { createContext } from "react";

export const ColumnColors = {
  blue: {
    main: "#00A4DF",
    line: "#C7F0FF",
    background: "#F2FCFF",
    startEndPointBack: "rgba(0, 164, 223, 0.3)",
    gradientLine: "#DDF6FF",
  },
  green: {
    main: "#00D9B1",
    line: "#BEFFF3",
    background: "#FFF2F8",
    startEndPointBack: "rgba(0, 217, 177, 0.3)",
    gradientLine: "#D8FFF8",
  },
  purple: {
    main: "#9620D9",
    line: "#F0D5FF",
    background: "#FFF2F8",
    startEndPointBack: "rgba(150, 32, 217, 0.3)",
    gradientLine: "#F9EFFF",
  },
  red: {
    main: "#F2278C",
    line: "#FFCEE6",
    background: "#FFF2F8",
    startEndPointBack: "rgba(242, 39, 140, 0.3)",
    gradientLine: "#FFEDF6",
  },
  yellow: {
    main: "#F2BB27",
    line: "#FFF0C7",
    background: "#FFF2F8",
    startEndPointBack: "rgba(242, 187, 39, 0.3)",
    gradientLine: "#FFF7E3",
  },
};

export const ColumnBackgrounds = {
  blue: {
    image: "/images/episode_background-blue.png",
  },
  green: {
    image: "/images/episode_background-green.png",
  },
  purple: {
    image: "/images/episode_background-purple.png",
  },
  red: {
    image: "/images/episode_background-red.png",
  },
  yellow: {
    image: "/images/episode_background-yellow.png",
  },
};

type ColumnTheme = {
  background: {
    image: string;
  };
  color: {
    main: string;
    line: string;
    background: string;
    startEndPointBack: string;
    gradientLine: string;
  };
};

const defaultContext = {
  background: ColumnBackgrounds.blue,
  color: ColumnColors.blue,
};

export const ColumnThemeContext = createContext<ColumnTheme>(defaultContext);

type Props = {
  children: React.ReactNode;
  value: ColumnTheme;
};

export const ColumnThemeContextProvider: React.FC<Props> = ({
  children,
  value,
}) => (
  <ColumnThemeContext.Provider value={value}>
    {children}
  </ColumnThemeContext.Provider>
);
