import styled from "@emotion/styled";
import { Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import Logo from "../Logo";

const { Text } = Typography;

type Props = {
  className?: string;
  children: string | React.ReactNode;
};

export const NoResult: FunctionComponent<Props> = ({ className, children }) => {
  return (
    <Root className={className}>
      <LogoWrapper>
        <Logo color="light" height={144} />
      </LogoWrapper>
      {typeof children === "string" ? (
        <Text color="light">{children}</Text>
      ) : (
        children
      )}
    </Root>
  );
};

const Root = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 50px 0;
  width: 100%;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;
