import { css } from "@emotion/css";
import { CircularProgress, Modal } from "@ibeckinc/ui-elements";
import { useSession } from "next-auth/react";
import React, {
  FunctionComponent,
  Suspense,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { toast } from "react-toastify";

import { respondSurvey, useGetSurveys } from "./query";
import { SurveyForm, SurveyFormValue } from "./SurveyForm";

export const SurveyModal: FunctionComponent = () => {
  const { data: session } = useSession();
  const { data: surveys } = useGetSurveys({ session });

  const [open, setOpen] = useState(surveys ? surveys?.length > 0 : false);

  const onSubmit = useCallback(
    async (e: SyntheticEvent, value: SurveyFormValue) => {
      e.preventDefault();
      try {
        await respondSurvey({
          id: value.id,
          rowOptions: value.rowOptions,
          session,
        });
        setOpen(false);
      } catch {
        toast.error("アンケートの送信に失敗しました");
      }
    },
    [session],
  );

  return (
    <Modal open={open} contentClassName={modalContentClass}>
      <Suspense fallback={<CircularProgress />}>
        {surveys && surveys?.length > 0 && (
          <SurveyForm survey={surveys[0]!} onSubmit={onSubmit} />
        )}
      </Suspense>
    </Modal>
  );
};

const modalContentClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 80%;
  max-width: 640px;
  padding: 24px;
  gap: 32px;
  overflow-y: scroll;
`;
