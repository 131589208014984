import { Background } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

type Props = {
  color?: "brand" | "white" | "light";
  height?: number;
};

const LogoAspect = 55 / 40;

const Logo: React.SVGAttributes<SVGElement> & FunctionComponent<Props> = ({
  color = "brand",
  height = 40,
  ...restProps
}) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={height * LogoAspect}
    viewBox="0 0 55 40"
    {...restProps}
  >
    <path
      d="M49.5928 40H54.2986V0.45249H48.1448V27.3303L28.5973 0L0 40H29.0498V33.8462H11.9457L28.5973 10.5882L49.5928 40Z"
      fill={
        color === "brand"
          ? "url(#paint0_linear_4551_11575)"
          : color === "light"
            ? Background.Light
            : Background.Base
      }
    />
    {color === "brand" && (
      <defs>
        <linearGradient
          id="paint0_linear_4551_11575"
          x1="0"
          y1="20"
          x2="54.2986"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BEE0" />
          <stop offset="1" stopColor="#00D9B0" />
        </linearGradient>
      </defs>
    )}
  </svg>
);

export default Logo;
