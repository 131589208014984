import crypto from "crypto";

const secretKey = "fd85b494-aaaa";
const secretIV = "smslt";
const encryptionMethod = "AES-256-CBC";

const key = crypto
  .createHash("sha512")
  .update(secretKey, "utf-8")
  .digest("hex")
  .slice(0, 32);
const iv = crypto
  .createHash("sha512")
  .update(secretIV, "utf-8")
  .digest("hex")
  .slice(0, 16);

export function encryptRecord(record: Record<string, any>): string {
  const encryptor = crypto.createCipheriv(encryptionMethod, key, iv);
  const aesEcryption =
    encryptor.update(JSON.stringify(record), "utf8", "base64") +
    encryptor.final("base64");
  return Buffer.from(aesEcryption).toString("base64");
}

export function descryptRecord(str: string): Record<string, any> {
  const encrypted = Buffer.from(str, "base64").toString("utf-8");
  const decryptor = crypto.createDecipheriv(encryptionMethod, key, iv);
  return JSON.parse(
    decryptor.update(encrypted, "base64", "utf8") + decryptor.final("utf8"),
  );
}
