export const DATE_FORMAT = {
  DEFAULT_DATE_FORMAT: "yyyy/MM/dd",
  DEFAULT_DATE_FORMAT_HYPHEN: "yyyy-MM-dd",
  DEFAULT_DATETIME_FORMAT: "yyyy/MM/dd HH:mm:ss",
  JP_DATETIME_SIMPLE_FORMAT: "yyyy/M/d(EEEEE) H:m",
  JP_DATE_FORMAT: "MM月dd日(EEEEE)",
  JP_DATE_SLASH_FORMAT: "MM/dd(EEEEE)",
  DATETIME_FORMAT: "HH:mm",
  JP_DATETIME_FORMAT: "MM月dd日(EEEEE) HH:mm",
  JP_DATETIME_SLASH_FORMAT: "MM/dd(EEEEE) HH:mm",
  JP_SIMPLE_DATE_FORMAT: "yyyy年MM月dd日",
  JP_YEAR_MONTH_FORMAT: "yyyy年MM月",
  JP_RESERVATION_FORMAT: "yyyy年MM月dd日 HH:mm",
  ISO_FORMAT: "yyyy-MM-dd'T'HH:mm:ssXX",
  W3_FORMAT: "yyyy-MM-dd'T'HH:mm:ss",
  SIMPLE_DATE_FORMAT: "yyyy.MM.dd",
  SIMPLE_TIME_FORMAT: "HH:mm",
};

export const WEEKDAYS = ["日", "月", "火", "水", "木", "金", "土"];
